import { Skrug } from "../../skrug";

import back from "./FlowerPoe_back.png"
import front from "./FlowerPoe.png"

export const Poe_Flower: Skrug = {

    collection: "Poe",

    name: "Flower Boy",

    etsy: "https://www.etsy.com/ca/shop/SkrugZone",
    
    yarn: ["Acrylic"],
    pile: ["Loop",],
    tags: ["Prototype"],

    price: 0,
    images: [
        front, back
    ],
    sold: {type: "NO SALE"}, 
    dimensions: {w: 2.5, h: 2.5},
    date: new Date("Jul 1 2021")
}