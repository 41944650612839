import { Skrug } from "../../skrug";

import back from "./Coffin_back.png"
import front from "./Coffin.png"

export const Coffin: Skrug = {

    collection: "None",

    name: "Coffin",

    etsy: "https://www.etsy.com/ca/shop/SkrugZone",

    yarn: ["Wool"],
    pile: ["Cut",],
    tags: ["Practice"],

    price: 150,

    images: [
        front, back
    ],
    dimensions: {w: 3, h: 2},
    date: new Date("Oct 1 2021")
}
