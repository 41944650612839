import { Skrug } from "./skrug"

import { Poe_Flower } from "./Rugs/Poe_Flower/rug"
import { GoodbyeCat_Prototype } from "./Rugs/GoodbyeCat_Prototype/rug"
import { Custom_Lordvirr } from "./Rugs/Custom_Lordvirr/rug"

import { Poe_Eyes } from "./Rugs/Poe_Eyes/rug"
import { Coffin } from "./Rugs/Coffin/rug"
import { Angerl } from "./Rugs/Angerl/rug"
import { Druul } from "./Rugs/Druul/rug"

import { GoodbyeCat_2 } from "./Rugs/GoodbyeCat_2/rug"
import { Custom_Paw } from "./Rugs/Custom_Paw/rug"
import { Spike } from "./Rugs/Spike/rug"

export const AllRugs: {[key in string]: Skrug} = {
    "@SKRU1": Poe_Flower, 
    "@SKRU2": GoodbyeCat_Prototype,
    "@SKRU3": Custom_Lordvirr,
    "@SKRU4": Poe_Eyes,
    "@SKRU5": Coffin,
    "@SKRU6": Angerl,
    "@SKRU7": Druul,
    "@SKRU8": GoodbyeCat_2,
    "@SKRU9": Custom_Paw,
    "@SKRU10": Spike,
}