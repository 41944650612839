import { Skrug } from "../../skrug";

import back from "./ColorEyePoe_back.png"
import front from "./ColorEyePoe.png"

export const Poe_Eyes: Skrug = {

    collection: "Poe",

    name: "6 Eyes",

    etsy: "https://www.etsy.com/ca/shop/SkrugZone",
    
    yarn: ["Wool"],
    pile: ["Cut",],
    tags: ["Practice"],

    price: 250,
    images: [
        front, back
    ],
    dimensions: {w: 3, h: 3},
    date: new Date("Oct 1 2021")
}
