import { Skrug } from "../../skrug";

// import back from "./GoodbyeCat2_back.png"
import front from "./GoodbyeCat_Prototype.jpeg"

export const GoodbyeCat_Prototype: Skrug = {

    collection: "Goodbye Cat",

    name: "Goodbye Cat #0",
    etsy: "https://www.etsy.com/ca/shop/SkrugZone",
    
    yarn: ["Acrylic"],
    pile: ["Cut",],
    tags: ["Prototype"],

    price: 0,
    images: [
        front
    ],
    dimensions: {w: 2.5, h: 2.5},
    date: new Date("Aug 1 2021"),
    sold: {type: "GIFTED"}
}
