import { Skrug } from "../../skrug";

import back from "./Spike_back.png"
import front from "./Spike.png"

export const Spike: Skrug = {

    collection: "None",

    name: "Spike",
    etsy: "https://www.etsy.com/ca/shop/SkrugZone",

    yarn: ["Acrylic"],
    pile: ["Cut",],
    tags: ["Practice"],

    price: 100,
    images: [
        front, back
    ],
    dimensions: {w: 2, h: 2},
    date: new Date("Dec 1 2021")
}
