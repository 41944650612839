import { Skrug } from "../../skrug";

import back from "./Paw_back.png"
import front from "./Paw.png"

export const Custom_Paw: Skrug = {

    collection: "Commission",

    name: "Paw",
    
    etsy: "https://www.etsy.com/ca/shop/SkrugZone",
    
    yarn: ["Acrylic"],
    pile: ["Cut",],
    tags: ["Practice",],

    price: 250,
    images: [
        front, back
    ],
    dimensions: {w: 3, h: 3},
    date: new Date("Dec 1 2021"),
    sold: {type: "SOLD"}
}
