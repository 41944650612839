import React from "react"

export type SkrugYarn = "Wool" | "Acrylic"
const _AllYarn: {[key in SkrugYarn]: boolean} = {"Wool":true, "Acrylic":true,};
export const AllYarn: SkrugYarn[] = Object.keys(_AllYarn) as SkrugYarn[]

export type SkrugPile =  "Loop" | "Cut"
const _AllPile: {[key in SkrugPile]: boolean} = {"Loop":true, "Cut":true, };
export const AllPile: SkrugPile[] = Object.keys(_AllPile) as SkrugPile[]

export type SkrugTag = "Prototype" | "Practice" | "Scuffed"
const _AllTags: {[key in SkrugTag]: boolean} = {"Prototype":true, "Practice": true};
export const AllTags: SkrugTag[] = Object.keys(_AllTags) as SkrugTag[]

export type SkrugCollection = "Poe" | "Goodbye Cat" | "An666el" | "Commission" | "None"
const _AllCollections: {[key in SkrugCollection]: boolean} = {"Poe":true, "Goodbye Cat":true, "An666el":true, "Commission":true, "None":true};
export const AllCollections: SkrugCollection[] = Object.keys(_AllCollections) as SkrugCollection[]

export type SaleType = "SOLD" | "GIFTED" | "NO SALE"
const _AllSaleType: {[key in SaleType]: boolean} = {"SOLD":true, "GIFTED":true, "NO SALE":true};
export const AllSaleType: SaleType[] = Object.keys(_AllSaleType) as SaleType[]

export interface Skrug {
    name: string,
    date: Date,

    dimensions: {w: number, h: number},
    price: {min: number, max: number} | number,

    etsy: string,

    yarn: SkrugYarn[],
    pile: SkrugPile[],
    tags: SkrugTag[],

    images: string[],

    on_sale?: number,
    // quantity?: number, // TODO

    sold?: {type: SaleType, who?: string}, // who owns it

    collection: SkrugCollection,
}


export class SkrugPage extends React.Component<{skrug:Skrug}, {}> {
    constructor(p) {
        super(p)
    }

    render(): React.ReactNode {
        const skr = this.props.skrug
        const mixedTags = skr.tags.concat(skr.yarn).concat(skr.pile)
        return <a className="Rug" href={skr.etsy}>
        <div className="ImageContainer"> <img src={skr.images[0]} /> <img className="hover" src={skr.images[1]} /> </div>
        <div className="InfoBar">
            <div className="Title"> {skr.name} </div> 
            <div> {months[skr.date.getMonth() - 1]} {skr.date.getFullYear()} </div>
        </div>
        <div className="InfoBar">
            <div>{skr.collection}</div>
            <div> {skr.dimensions.w} x {skr.dimensions.h} ft </div> 
        </div>
        <div className="Tags" children={mixedTags.flatMap((tag)=>{return <div className={"Tag " + tag}>{tag}</div>})}/>
        <div className="InfoBar">
            <div className="Title"> </div> 
            <div className="Price">
            {skr.sold ? "SOLD" : ("$" + (skr.price.min ? (skr.price.min + " " + skr.price.max) : skr.price))}
            </div>
        </div>
        </a>
    }
}