import { Skrug } from "../../skrug";

import back from "./lord_back.jpeg"
import front from "./lord.jpeg"
import cody from "./cod.jpeg"

export const Custom_Lordvirr: Skrug = {

    collection: "Commission",

    name: "Lordvirr",

    etsy: "https://www.etsy.com/ca/shop/SkrugZone",

    yarn: ["Wool"],
    pile: ["Cut",],
    tags: ["Practice"],

    price: 0,
    images: [
        front, back, cody
    ],
    dimensions: {w: 2, h: 2.5},
    date: new Date("Oct 1 2021"),
    sold: {type: "GIFTED"}
}
