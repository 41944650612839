import { Skrug } from "../../skrug";

import back from "./Druul_back.png"
import front from "./Druul.png"

export const Druul: Skrug = {

    collection: "None",

    name: "Druul",

    etsy: "https://www.etsy.com/ca/shop/SkrugZone",

    yarn: ["Acrylic"],
    pile: ["Cut",],
    tags: ["Practice"],

    price: 120,
    images: [
        front, back
    ],
    dimensions: {w: 2, h: 2.5},
    date: new Date("Oct 1 2021")
}
