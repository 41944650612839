import { Skrug } from "../../skrug";

import back from "./Angel_back.png"
import front from "./Angel.png"

export const Angerl: Skrug = {

    name: "An666el_1",

    collection: "An666el",

    etsy: "https://www.etsy.com/ca/shop/SkrugZone",

    yarn: ["Acrylic"],
    pile: ["Cut",],
    tags: ["Practice"],

    price: 75,

    images: [
        front, back
    ],
    
    dimensions: {w: 2, h: 2.5},
    date: new Date("Oct 1 2021")
}
