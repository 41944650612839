import { Skrug } from "../../skrug";

import back from "./GoodbyeCat2_back.png"
import front from "./GoodbyeCat2.png"

export const GoodbyeCat_2: Skrug = {

    collection: "Goodbye Cat",

    name: "Goodbye Cat #2",
    etsy: "https://www.etsy.com/ca/listing/1339053639/goodbye-cat-2-cut-pile-acrylic-yarn",

    yarn: ["Acrylic"],
    pile: ["Cut",],
    tags: ["Practice", "Scuffed"],

    price: 150,
    on_sale: 100,
    images: [
        front, back
    ],
    dimensions: {w: 2.5, h: 2},
    date: new Date("Dec 1 2021")
}
