import React, { ReactElement } from "react"

// import Bio from "../components/bio"
// import SEO from "../components/seo"

require("./rugs.less");

import { BasePage } from '../components/Base Page'
import { ContentCard } from "../components/Content Card/ContentCard";
import { AllRugs } from "../skrugs/all_rugs";
import { AllCollections, AllPile, AllSaleType, AllTags, AllYarn, SaleType, Skrug, SkrugCollection, SkrugPile, SkrugTag, SkrugYarn } from "../skrugs/skrug";

const soldOffset = () => {
  return {
    marginLeft: (-10 + Math.random() * 20) + "px",
    marginTop: (-10 + Math.random() * 20) + "px"
  }
}

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
class Rug extends React.Component<{skrug:Skrug}, {}> {
  
  render(): React.ReactNode {

    const skr = this.props.skrug
    const soldCss = skr.sold?.type.replace(" ","_") ?? "";
    const mixedTags: string[] = (skr.yarn as string[]).concat(skr.pile.map(p=>p + " Pile"))

    const dimStr = (dims: {w:number, h:number}) => {
      const max = (dims.w + dims.h) / 2
      return max >= 3 ? "MEDIUM" : max >= 4 ? "LARGE" : max >= 5 ? "HUGE" : "SMALL"
    }
    const rugSize = dimStr(skr.dimensions);

    var soldOffsetK = 0
    return <a key={skr.name} className={"Rug " + soldCss} href={skr.etsy}>
      <div key={"container" + skr.name} className="ImageContainer"> 
        <img src={skr.images[0]} /> 
        {skr.sold ? <div key={"no stock" + skr.name} className="NO_STOCK"> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
          <p key={(soldOffsetK++).toString()} style={soldOffset()}>{ skr.sold.type }</p> 
        </div> : null }
      </div>
      <div key={"info" + skr.name} className="InfoBar">
        <div key={"info title" + skr.name} className="Title"> {skr.name} </div> 
        <div key={"date" + skr.name}> {months[skr.date.getMonth()]} {skr.date.getFullYear()} </div>
      </div>
      <div key={"dims" + skr.name} className="InfoBar-Scroll">
        <div className={"Size-" + rugSize}>{rugSize}</div>
        <div key={"dimsss" + skr.name} style={{display: "flex"}}>~ <p className="Box">{skr.dimensions.w}</p> x <p className="Box">{skr.dimensions.h}</p>ft </div> 
      </div>
      <div key={"mixed tags" + skr.name} className="Tags" children={mixedTags.flatMap((tag)=>{return <div key={tag + skr.name} className={"Tag " + tag}>{tag}</div>})}/>
      <div key={"tags" + skr.name} className="Tags" children={skr.tags.flatMap((tag)=>{return <div key={tag + skr.name} className={"Tag " + tag}>{tag}</div>})}/>
      <div key={"collection and price" + skr.name} className="InfoBar">
        <p key={"collection" + skr.name}  className="Collection">{ skr.collection != "None" ? skr.collection : null } </p>
        <div />
        <div key={"price" + skr.name} className={"Price " + soldCss}>
          <p key="base price" className={(skr.on_sale ? "ON_SALE Scuffed" : "")}>{("$" + (skr.price.min ? (skr.price.min + " " + skr.price.max) : skr.price))}</p>
          {skr.on_sale ? <p>${skr.on_sale} </p> : null}
        </div>
      </div>
    </a>
  }
}

type ForSaleType = ("SOLD" | "FOR SALE");

class HomePage extends React.Component<{}, {
  sort: "date up" | "date down" | "price up" | "price down",
  collection: SkrugCollection[],
  tags: SkrugTag[],
  yarn: SkrugYarn[],
  pile: SkrugPile[],
  showSold: ForSaleType[],
  showFilters: boolean
}> {

  constructor(p) {
    super(p);
    this.state = {
      showFilters: false, 
      sort: "date down", 
      collection: AllCollections,
      tags: AllTags,
      pile: AllPile,
      yarn: AllYarn,
      showSold: ["SOLD", "FOR SALE"]
    };
  }

  public render() {
    const rugs = []

    const sorted = Object.values(AllRugs).sort((a,b)=>{

      const sort = (aP, bP, aD, bD,) => {
        switch(this.state.sort) {
          case "price down":
            return (bP ?? 0) - (aP ?? 0)
          case "price up":
            return (aP ?? 10000) - (bP ?? 10000)
          case "date down":
            return (bD ?? new Date(0, 1)) - (aD ?? new Date(0, 1))
          default:
            return (aD ?? new Date(2808, 1)) - (bD ?? new Date(2808, 1))
        }
      }

      const aPrice = typeof a.price === "number" ? a.price : a.price.min
      const bPrice = typeof b.price === "number" ? b.price : b.price.min
      
      if((a.sold && b.sold) || (!a.sold && !b.sold)) {
        return sort(aPrice, bPrice, a.date, b.date)
      } else if(a.sold) {
        return sort(null, bPrice, null, b.date)
      } else {
        return sort(aPrice, null, a.date, null)
      }

    });

    const tagged = sorted.filter((s) => {
      if(this.state.collection.indexOf(s.collection) < 0) return false;

      var keepT = false
      this.state.tags.forEach(t => {
        keepT = keepT || s.tags.indexOf(t) >= 0
      });

      var keepY = false
      this.state.yarn.forEach(y => {
        keepY = keepY || s.yarn.indexOf(y) >= 0
      });

      var keepP = false
      this.state.pile.forEach(p => {
        keepP = keepP || s.pile.indexOf(p) >= 0
      });

      var keepS = true
      if(s.sold) {
        keepS = this.state.showSold.indexOf("SOLD") >= 0
      } else {
        keepS = this.state.showSold.indexOf("FOR SALE") >= 0
      }
      
      return keepP && keepY && keepT && keepS
    });

    tagged.forEach(rug => {
      rugs.push(<Rug key={"rug" + rug.name} skrug={rug} />)
    });

    const addRemoveTagButton = (t: SkrugTag) => {
      const addRemoveTag = (t: SkrugTag) => {
        if(this.state.tags.indexOf(t) < 0) {
          this.setState({tags: this.state.tags.concat(t)})
        } else {
          this.setState({tags: this.state.tags.filter((_t) => { return _t != t })})
        }
      }
      return <button key={t} className={this.state.tags.indexOf(t) >=0 ? t+ " Filter-Selected" : ""} onClick={()=>{addRemoveTag(t)}} > {t} </button>
    }

    const addRemoveSoldButton = (t: ForSaleType) => {
      const addRemoveTag = (t: ForSaleType) => {
        if(this.state.showSold.indexOf(t) < 0) {
          this.setState({showSold: this.state.showSold.concat(t)})
        } else {
          this.setState({showSold: this.state.showSold.filter((_t) => { return _t != t })})
        }
      }
      return <button key={t} className={"Sale" + (this.state.showSold.indexOf(t) >=0 ? " Filter-Selected" : "")} onClick={()=>{addRemoveTag(t)}} > {t} </button>
    }

    const addRemovePileButton = (t: SkrugPile) => {
      const addRemoveTag = (t: SkrugPile) => {
        if(this.state.pile.indexOf(t) < 0) {
          this.setState({pile: this.state.pile.concat(t)})
        } else {
          this.setState({pile: this.state.pile.filter((_t) => { return _t != t })})
        }
      }
      return <button key={t} className={this.state.pile.indexOf(t) >=0 ? t+ " Filter-Selected" : ""} onClick={()=>{addRemoveTag(t)}} > {t} </button>
    }

    const addRemoveYarnButton = (t: SkrugYarn) => {
      const addRemoveTag = (t: SkrugYarn) => {
        if(this.state.yarn.indexOf(t) < 0) {
          this.setState({yarn: this.state.yarn.concat(t)})
        } else {
          this.setState({yarn: this.state.yarn.filter((_t) => { return _t != t })})
        }
      }
      return <button key={t} className={this.state.yarn.indexOf(t) >=0 ? t+ " Filter-Selected" : ""} onClick={()=>{addRemoveTag(t)}} > {t} </button>
    }

    const addRemoveCollectionButton = (c: SkrugCollection) => {
      const addRemoveCollection = (c: SkrugCollection) => {
        if(this.state.collection.indexOf(c) < 0) {
          this.setState({collection: this.state.collection.concat(c)})
        } else {
          this.setState({collection: this.state.collection.filter((_c) => { return _c != c })})
        }
      }
      return <button key={c} className={"Collection " + (this.state.collection.indexOf(c) >=0 ? " Filter-Selected" : "")} onClick={()=>{addRemoveCollection(c)}} > {c} </button>
    }

    /* <button className="Dropdown">
      <div className="Dropdown-Button">Sort</div>
      <div className="Dropdown-Content Dropdown-Content-Left" children={[
      ]} />
    </button> */

    return (
      <BasePage 
      
        key="index page"
        children={[
          <ContentCard key="Contents" children={[

            <div key="filterContainer" className="Filter-Container" children={[
              <div key="filters topbar">
                <button key="filterButton" onClick={()=>{this.setState({showFilters: !this.state.showFilters});}}> {!this.state.showFilters ? "+ Filters" : "- Filters"} </button>
                <div key="currency" style={{float: "right"}}>$ CAD 🇨🇦</div>
              </div>,
              !this.state.showFilters ? null : <div key="filters" className="Filters" children={[

                <div key="shop filters" className="Sorting">
                  <h1 key="shop title" className="">Shop </h1> 
                  <div key="shop box"className="Box">
                    {addRemoveSoldButton("FOR SALE")}+
                    {addRemoveSoldButton("SOLD")}
                  </div>
                </div>,

                <div key="pile filters" className="Sorting">
                  <h1 key="pile title" className="">Pile </h1> 
                  <div key="pile box" className="Box">
                    {addRemovePileButton("Cut")}+
                    {addRemovePileButton("Loop")}
                  </div>
                </div>,

                <div key="yarn filters" className="Sorting">
                  <h1 key="yarn title" className="">Yarn </h1> 
                  <div key="yarn box" className="Box">
                    {addRemoveYarnButton("Acrylic")}+
                    {addRemoveYarnButton("Wool")}
                  </div>
                </div>,

                <div key="collection filters" className="Sorting">
                  <h1 key="collection title" className="">Collection </h1> 
                  <div key="collection box" className="Box">
                    {addRemoveCollectionButton("Poe")}+
                    {addRemoveCollectionButton("Goodbye Cat")}+
                    {addRemoveCollectionButton("Commission")}+
                    {addRemoveCollectionButton("An666el")}+
                    {addRemoveCollectionButton("None")}
                  </div>
                </div>,

                <div key="sort filters" className="Sorting">
                  <h1 key="sort title" className="">Sorting</h1> 
                  <div key="sort box" className="Box"> 
                    <b key="date title">Date</b>
                    <button key="date down" className={this.state.sort == "date down" ? "Filter-Selected" : ""} onClick={()=>{this.setState({sort: "date down"})}} > ↓ </button>
                    <button key="date up" className={this.state.sort == "date up" ? "Filter-Selected" : ""} onClick={()=>{this.setState({sort: "date up"})}} > ↑ </button>|
                    <b key="price title">Price</b>
                    <button key="price down" className={this.state.sort == "price down" ? "Filter-Selected" : ""} onClick={()=>{this.setState({sort: "price down"})}} > ↓ </button>
                    <button key="price up" className={this.state.sort == "price up" ? "Filter-Selected" : ""} onClick={()=>{this.setState({sort: "price up"})}} > ↑ </button>
                  </div> 
                </div>,
              ]}/>
              

            ]}/>,

            
            rugs.length > 0 ? <div key="rug list" className="RugList" children={rugs} /> : {type: "Warning", style:{marginTop: "30px", marginLeft: "auto", marginRight: "auto"}, content: "No Skrugs to show with current filters."},
            {type: "Text", style: {marginLeft:"auto", paddingTop:"100px", marginRight:"auto"}, content: <a key="etsy link" href="https://www.etsy.com/ca/shop/SkrugZone">Visit Etsy Shop</a>},

          ]} />
        ]}
        currentSection="RUGS"
      />
    )
  }
}

export default HomePage;
